import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import 'moment/locale/nl';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import moment from 'moment';

import i18n from './i18n';

import './styles/style.scss';

import 'jquery/dist/jquery';
import 'jquery-easing/dist/jquery.easing.1.3.umd';
import 'bootstrap/dist/js/bootstrap.min';

import History from './utilities/History';

import App from './App';
import Bostik from './content/embed/Bostik';
import EmbedPlayer from './content/embed-player';

smoothscroll.polyfill();

moment.locale(i18n.language);

ReactDOM.render(
  <React.Suspense fallback="Loading...">
    <React.StrictMode>
      <Router history={History}>
        <Switch>
          <Route path="/embed/bostikbrandday" component={Bostik} />
          <Route
            path="/embed/willemii"
            render={() => {
              window.location.href = 'https://www.willem-ii.nl/';
              return null;
            }}
          />
          <Route path="/bulls-and-bears/:identifier" component={EmbedPlayer} />
          <Route path="/embed/:identifier" component={EmbedPlayer} />
          <Route render={() => <App />} />
        </Switch>
      </Router>
    </React.StrictMode>
  </React.Suspense>,
  document.getElementById('root'),
);
