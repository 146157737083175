import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translation } from 'react-i18next';
import io from 'socket.io-client';
import moment from 'moment';
import { Howl } from 'howler';
import { deviceDetect } from 'react-device-detect';

import config from '../../config';
import i18n from '../../i18n';

import {
  IRegistrationWebinarStream,
  TRegistration,
} from '../../interfaces/IRegistration';
import { IStatement } from '../../interfaces/IStatement';
import { IChatMessage } from '../../interfaces/IChat';
import { ISurveyQuestion } from '../../interfaces/ISurvey';

import VideoPlayer from './components/VideoPlayer';
import EWebinarState from '../../enums/EWebinar';
import { IPlayerJoinedBreakoutSession } from '../../interfaces/IPlayer';
import PlyrStyle from './components/PlyrStyle';

const {
  api: { endpoint },
} = config;

interface MatchParams {
  identifier: string;
}

export type EmbedPlayerProps = RouteComponentProps<MatchParams>;

export interface EmbedPlayerState {
  // initialConnect: boolean;
  identifier: string;
  registration?: TRegistration;
  registrationFailed: boolean;
  startTimer?: ReturnType<typeof setTimeout>;
  chat?: IChatMessage[];
  statement?: IStatement;
  message?: string;
  joinedBreakoutSession?: IPlayerJoinedBreakoutSession;
  breakoutSessionCountdown?: moment.Moment;
  speeddatesStartDate?: moment.Moment;
  joinedSpeeddate?: string;
  nextSpeeddateRound?: moment.Moment;
  speeddateRoundsRemaining?: number;
  waitForSpeeddateParticipant?: true;
  noNewSpeeddateOpponent?: boolean;
  survey?: ISurveyQuestion[];
  surveyInput?: string;
}

class EmbedPlayer extends React.Component<EmbedPlayerProps, EmbedPlayerState> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  notification = new Howl({
    src: '/static/audio/notifications.mp3',
    sprite: {
      maybe: [0, 2000],
      sure: [2000, 2000],
      undeniable: [4000, 2000],
      accomplished: [6000, 2000],
    },
    volume: 0.7,
  });

  constructor(props: EmbedPlayerProps) {
    super(props);

    const {
      match: {
        params: { identifier },
      },
    } = this.props;
    this.state = {
      // initialConnect: true,
      identifier,
      registrationFailed: false,
      // joinedSpeeddate:
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJxYl93ZWJpbmFyIiwicm9vbSI6IjA0ZDYyZjY2LWIyZTEtNDNhYS1iN2EzLWRmOGE2Y2ExYWE2MyIsImV4cCI6MTYxMDI5ODE3MSwic3ViIjoicWJyb2FkY2FzdGluZyIsImF1ZCI6InFiX2JyZWFrb3V0IiwiY29udGV4dCI6eyJ1c2VyIjp7ImlkIjoiYWVmZWY4OGItZTNlZS00NDhhLWJjZDQtZWEyOWFhMzFkODkzIiwibmFtZSI6IlJlaW4gdmFuIEhhYXJlbiJ9LCJjYWxsZWUiOnsiaWQiOiJhZWZlZjg4Yi1lM2VlLTQ0OGEtYmNkNC1lYTI5YWEzMWQ4OTMiLCJuYW1lIjoiUmVpbiB2YW4gSGFhcmVuIn0sImdyb3VwIjoiMDRkNjJmNjYtYjJlMS00M2FhLWI3YTMtZGY4YTZjYTFhYTYzIn0sImlhdCI6MTYxMDI5NDU3MX0.fjx2EMKHPqk-gQyZhXrACjtwAXyHxBWkRMJP01mTXHo',
      // nextSpeeddateRound: moment().add(20, 'seconds'),
      // waitForSpeeddateParticipant: true,
      // statement: {
      //   id: 1,
      //   statement:
      //     'De CO2-uitstoot in grote steden moet beter worden aangepakt',
      //   answers: [
      //     { key: 0, answer: 'Gisteren dacht ik er nog anders over' },
      //     { key: 1, answer: 'Vraag me er morgen anders nog een keer naar' },
      //     {
      //       key: 2,
      //       answer:
      //         "Wellicht als je de directeur van Interpol belt dat'ie een nuttig antwoord kan geven",
      //     },
      //   ],
      // },
      // message:
      //   'Dit is een antwoord op bijvoorbeeld een vraag die gesteld is. Groetjes van een meneer die dit typt',
      // joinedBreakoutSession: {
      //   identifier: '26e58e12-9946-45b9-bd52-3f1c9850a72c',
      //   participantName: 'Rein van Haaren',
      //   roomName: 'aa TEST 1',
      //   withChat: true,
      // },
      // joinedBreakoutSession: {
      //   roomName: 'aa TEST 1',
      //   identifier: '61148ed0-db41-4981-952e-e2042588acbc',
      //   withChat: true,
      // },
    };
  }

  componentDidMount() {
    this.io.open();
    this.io.on('connect', this.handleConnect);
    this.io.on('disconnect', this.handleDisconnect);
    this.registerIoEventHandlers();
  }

  handleConnect = (): void => {
    const { identifier } = this.state;

    this.io.emit('registerPlayer', identifier, JSON.stringify(deviceDetect()));
  };

  handleDisconnect = (): void =>
    this.setState({
      statement: undefined,
      message: undefined,
      speeddatesStartDate: undefined,
    });

  registerIoEventHandlers = (): void => {
    this.io.on('registered', (registration: TRegistration) => {
      this.setState({
        registration: {
          ...registration,
          webinar: {
            ...registration.webinar,
            // start,
          },
        },
      });

      if (registration.webinar.webinarId === 164) {
        document.body.classList.add('samsung');

        i18n.changeLanguage('en-US');
      }

      this.setStartTimer(registration.webinar.start);

      document.title = registration.webinar.name;
    });

    this.io.on('registrationFailed', () =>
      this.setState({ registrationFailed: true }),
    );

    this.io.on('refreshPage', () => window.location.reload());

    this.io.on(
      'webinarState',
      (state: EWebinarState, stream?: IRegistrationWebinarStream) => {
        const { registration } = this.state;

        if (registration) {
          registration.webinar.stream = stream;
          registration.webinar.state = state;

          this.setState({ registration });
        }

        if (state === EWebinarState.pre) this.setState({ survey: undefined });
      },
    );

    this.io.on('survey', (survey?: ISurveyQuestion[]) =>
      this.setState({ survey }),
    );

    this.io.on('statement', (statement: IStatement) => {
      if (statement) this.notification.play('sure');
      this.setState({ statement });
    });

    this.io.on('message', (message: string) => {
      this.notification.play('sure');
      this.setState({ message });
    });
  };

  setStartTimer = (start: string) => {
    const { startTimer } = this.state;
    const ms = moment(start).diff(moment(), 'milliseconds');
    let newTimer;

    if (startTimer) clearTimeout(startTimer);
    if (ms >= 0) newTimer = setTimeout(() => this.forceUpdate(), ms);

    this.setState({ startTimer: newTimer });
  };

  handleStatementAnswer = (id: number, answer: number) => {
    this.notification.play('maybe');

    this.io.emit('statementVote', id, answer);
    this.setState({ statement: undefined });
  };

  handleStatementDissmiss = () => {
    this.notification.play('maybe');
    this.setState({ statement: undefined });
  };

  handleMessageDissmiss = () => this.setState({ message: undefined });

  handleSendQuestion = (question: { name?: string; question: string }) => {
    this.notification.play('accomplished');
    this.io.emit('question', question);
  };

  handleJoinSpeeddates = (name?: string): void => {
    if (name) localStorage.setItem('participantName', name);

    this.io.emit('joinSpeeddates', name);
  };

  handleSetSurveyInput = (surveyInput: string): void =>
    this.setState({ surveyInput });

  handleSurveyQuestionAnswer = (id: number, answer?: string | number): void => {
    let { survey } = this.state;

    if (survey) {
      if (survey.length === 0 || (id === 0 && answer === 0)) survey = undefined;
      else {
        if (typeof answer !== 'undefined')
          this.io.emit('surveyAnswer', id, answer);

        survey.shift();
      }

      this.setState({ survey });
    }
  };

  render() {
    const {
      registration,
      registrationFailed,
      statement,
      message,
      speeddatesStartDate,
      survey,
      surveyInput,
    } = this.state;

    const webinarState = registration?.webinar.state;

    return (
      <>
        {registrationFailed ? (
          <div className="container h-100 d-flex flex-column align-items-center mt-5">
            <h3 className="display-3">
              <i className="fas fa-times-circle fa-fw text-danger" />
            </h3>
            <h1 className="h2">
              <Translation>
                {(t) => t('webinar.registrationFailed.title')}
              </Translation>
              ...
            </h1>
            <span className="text-muted text-center">
              <Translation>
                {(t) => t('webinar.registrationFailed.text')}
              </Translation>
            </span>
          </div>
        ) : !registration ? (
          <div className="text-center mt-4">
            <div
              className="spinner-grow"
              style={{ width: '3rem', height: '3rem' }}
              role="status"
            >
              <span className="sr-only">
                <Translation>
                  {(t) =>
                    `${t('common.loading').charAt(0).toUpperCase()}${t(
                      'common.loading',
                    ).slice(1)}`
                  }
                </Translation>
                ...
              </span>
            </div>
          </div>
        ) : (
          <>
            <PlyrStyle
              textColor={registration.webinar.settings.textColor}
              backgroundColor={registration.webinar.settings.backgroundColor}
              accentColor={registration.webinar.settings.accentColor}
            />
            {webinarState !== EWebinarState.live ? (
              <div
                id="content-wrapper"
                style={{
                  display: 'flex',
                  height: '100vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h2 className="text-center">
                  {webinarState === EWebinarState.pre
                    ? 'De uitzending is nog niet begonnen'
                    : 'De uitzending is afgelopen'}
                </h2>
              </div>
            ) : (
              <>
                {registration.webinar.stream && (
                  <VideoPlayer
                    stream={registration.webinar.stream}
                    statement={statement}
                    message={message}
                    nameRequired={registration.webinar.nameRequired}
                    disableQuestions={registration.webinar.disableQuestions}
                    settings={registration.webinar.settings}
                    speeddatesStartDate={speeddatesStartDate}
                    breakoutSessions={registration.webinar.breakoutSessions}
                    survey={survey}
                    surveyInput={surveyInput}
                    onStatementAnswer={this.handleStatementAnswer}
                    onStatementDissmiss={this.handleStatementDissmiss}
                    onSendQuestion={this.handleSendQuestion}
                    onMessageDissmiss={this.handleMessageDissmiss}
                    onJoinSpeeddates={this.handleJoinSpeeddates}
                    onSurveyQuestionAnswer={this.handleSurveyQuestionAnswer}
                    onSetSurveyInput={this.handleSetSurveyInput}
                    isVOD={false}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default EmbedPlayer;
